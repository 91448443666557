<template>
  <div class="shop">
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <div>
        <van-search
          v-model="search"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        >
          <template #left-icon>
            <img
              src="https://lyxnyapi.dexian.ren//upload/images/2022/7/cfe5e07a-158.png"
              alt=""
              width="14"
              height="14"
            />
          </template>
        </van-search>
      </div>
      <van-tabs
        @click="onClickOnlyOne"
        color="#f63e3b"
        title-active-color="#f63e3b"
      >
        <van-tab name="全部" title="全部"> </van-tab>
        <van-tab
          v-for="item in CateList"
          :key="item.CateId"
          :name="item.CateId"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs>
    </van-sticky>
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list
        v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="center" v-for="item in list" :key="item.index">
          <router-link :to="'/huayang/index/' + item.ProjectId">
            <van-card style="padding: 10px 0px; background-color: white">
              <template #thumb>
                <div
                  class="van-card__thumb"
                  style="margin-right: 22px; width: 90px; height: 90px"
                >
                  <img
                    v-if="!item.Img"
                    style="width: 100%; height: 100%"
                    src="https://dximg.dexian.ren/sxjgz/images/project.png"
                  />
                  <img
                    v-else
                    style="width: 100%; height: 100%"
                    :src="item.Img.split(',')[0]"
                  />
                </div>
              </template>
              <template #title>
                <div
                  class="centerTitle van-ellipsis--l1"
                  style="width: 75%; padding-bottom: 10px; font-size: 16px"
                >
                  {{ item.Name }}
                </div>
              </template>

              <template #tags>
                <div style="padding-bottom: 5px; font-size: 13px">
                  <van-icon name="manager" /> {{ item.Charger }}
                </div>
                <div style="padding-bottom: 5px; font-size: 13px">
                  <van-icon name="phone" />
                  <a :href="'tel:' + item.TouchTel" style="color: black">{{
                    item.TouchTel
                  }}</a>
                </div>
                <div
                  class="van-multi-ellipsis--l2"
                  v-if="item.Address != ''"
                  style="width: 75%; padding-bottom: 5px; font-size: 13px"
                >
                  <van-icon name="location" /> {{ item.Address }}
                </div>
                <!-- <div class="county"
                @click="gotominiProgram(item)">
                <img src="https://xczxapi.zhsq.cloud//upload/images/2022/6/b10693b0-c37.png"
                  alt=""
                  style="width: 24px;">
              </div> -->
              </template>
            </van-card>
          </router-link>
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { WxGetProjectPage, WxGetProjectCateList } from "@/api/RealInfo";
export default {
  data() {
    return {
      CateList: [],
      riKind: "", //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 获取资讯分类
    async getInfoKindInfoPage() {
      const res = await WxGetProjectCateList();
      console.log(res, "res");
      this.CateList = res.data.data;
      console.log(this.CateList, "this.CateList");
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.riKind = name;
      this.listfrom.page = 1;
      this.getList();
    },
    // 获取分页列表
    getList() {
      this.listfrom.cateId = this.riKind;
      this.listfrom.name = this.search;
      WxGetProjectPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              // this.list = {};
              this.total = res.data.count;
              this.finished = true;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                // if (this.list[i].ShopImgUrl != "rejected") {
                //   this.list[i].ShopImgUrl =
                //     "https://lyxnyapi.dexian.ren//upload/images/2022/7/b93cef0f-689.png";
                // }
              }
            }
          }
        })
        .catch(() => {});
    },
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    // onClick(e) {
    //   this.riKind = e;
    //   this.listfrom.page = 1;
    //   this.getList();
    // },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.getList();
    this.getInfoKindInfoPage();
  },
};
</script>
<style scoped>
.van-card__thumb {
  margin-right: 22px;
  margin-left: 5px;
}
.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  /* background: rgb(246, 243, 243); */
}
.centertime {
  font-size: 14px !important;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}
</style>